<template>
  <div class="text-center">
    <h1 class="display-3 text-center text-white">Vestergaard Software Download</h1>
  </div>
</template>

<script>
export default {
  name: "Hero"
};
</script>
<style>
  body {
    background: #00B4DB;
    background: -webkit-linear-gradient(to right, #0083B0, #00B4DB);
    background: linear-gradient(to right, #0083B0, #00B4DB);
    color: #514B64;
    min-height: 100vh;
  }
</style>