
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  name: "HomeContent",
  setup() {
    const { isAuthenticated } = useAuth0();
    return {
      isAuthenticated
    }
  }
};
