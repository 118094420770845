<template>
  <div id="app" class="d-flex flex-column">
    <nav-bar />
    <div class="container flex-grow-1">
      <error />
      <div class="mt-5">
        <router-view />
      </div>
    </div>
    <footer class="bg-light text-center custom-footer">
      <div class="alert alert-info custom-margin-bottom" role="alert">
        You'll need to authenticate to access the download,
        <a href="https://vestergaard.atlassian.net/servicedesk/customer/portal/2"
          class="btn btn-info btn-dark"> Contact us</a> if you are missing access.
      </div>
    </footer>
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import Error from "./components/Error";

export default {
  components: {
    NavBar,
    Error
  }
};
</script>
<style scoped>
  .custom-margin-bottom {
    margin-bottom: 0px;
  }

  .custom-footer{
    position:sticky;
    bottom:0;
    width: 100%;
  }
</style>